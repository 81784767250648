import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { Content } from '../../../common/content';
import './columnteaser.scss';
import {CONTENT_MAPPING} from "../../mapping";
import {Renderer} from "../../../common/renderer";

const bem = new BEMHelper({
  name: 'content-columnteaser',
});

export const Columnteaser = ({ title, columns }) => {
  const { t } = useTranslation();

  if (columns.length === 0) {
    return null;
  }

  const wrapperClass = title ? bem() : bem(null,'no-headline');

  return (
    <Content {...wrapperClass}>
      {title && (<h2 {...bem('headline')}>{title}</h2>)}

      <div {...bem('main')}>
        {columns.map(({
          slug,
          title,
          contents
        }, index) =>{
          return (
            <Renderer contents={contents} mapping={CONTENT_MAPPING} key={'columnteaserkey-' + index} />
          )
        })}
      </div>

    </Content>
  );
};


Columnteaser.propTypes = {
  title: string,
  columns: arrayOf(shape({
    title: string.isRequired,
    slug: string.isRequired,
  })).isRequired,
};

Columnteaser.defaultProps = {
  title: undefined,
  columns: undefined,
};

export const fragment = graphql`
  fragment ColumnteaserContentFragment on CraftGQL_pageContents_columnTeaser_BlockType {
    title
    columns {
      ...on CraftGQL_columnTeaser_teaser_Entry {
        title
        slug
        contents: pageContents {
          ... on CraftGQL_pageContents_pageTeaserM_BlockType {
            id
            typename: __typename,
            headline
            description
            image {
              ...Picture
              fluid_0: url(width: 480, mode: "crop", position: "") # 0.25
              fluid_1: url(width: 960, mode: "crop", position: "") # 0.5
              fluid_2: url(width: 1920, mode: "crop", position: "") # max width
            }
            reference {
              text
              element {
                ...on CraftGQL_pages_pages_Entry {
                  __typename,
                  slug: pageSlug
                  departments: pageDepartments {
                    ...on CraftGQL_departments_department_Entry {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
